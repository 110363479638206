/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link';
import Img from 'gatsby-image';
import {
  Box,
  Heading,
  jsx,
  Styled,
  AspectRatio,
  Label,
  Input,
  Textarea,
  Button,
} from 'theme-ui';
import PortableText from '../components/PortableText';
import { SEO, useSiteMetadata } from '../utils';

import ContentContainer from '../components/content-container';

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const ContactHeading = ({ ...props }) => (
  <Heading
    as="h3"
    {...props}
    sx={{
      pt: 3,
      fontWeight: '600',
      color: 'dark',
    }}
  />
);

const Page = ({ data: { page } }) => {
  const ternaryObjectStyles = {
    objectPosition: page?.mainImage?.hotspot
      ? `${page.mainImage.hotspot.x * 100}% ${page.mainImage.hotspot.y * 100}%`
      : 'center center',
  };

  const [state, setState] = React.useState({});
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  const metaData = useSiteMetadata();
  const { address } = metaData.sanityData;

  return (
    <>
      <SEO title={page.title} />
      <Box>
        {page?.mainImage?.asset?.fluid ? (
          <>
            <Img
              fluid={page.mainImage.asset.fluid}
              sx={{
                maxHeight: ['800px'],
              }}
              imgStyle={ternaryObjectStyles}
            />
          </>
        ) : (
          <AspectRatio
            ratio={20 / 2}
            sx={{
              p: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'background',
              bg: 'primary',
            }}
          ></AspectRatio>
          //   <Heading
          //     as="h1"
          //     sx={{
          //       fontFamily: 'fancyFont',
          //       fontSize: [4, 5, '3.3rem'],
          //       pb: [3, 2, 0],
          //     }}
          //   >
          //     {page.title}
          //   </Heading>
        )}
        <ContentContainer>
          <Heading
            as="h1"
            sx={{ py: 3, fontFamily: 'fancyFont', fontSize: [5, '3.3rem'] }}
          >
            {page.title}
          </Heading>
          <PortableText blocks={page._rawBody} />
          <ContactHeading>Address</ContactHeading>
          <p>
            {address.streetNo} {address.street} <br />
            {address.city}, {address.state} {address.zip} <br />
          </p>

          <ContactHeading>Phone</ContactHeading>
          <p>
            <Styled.a
              href={`tel:+1${metaData.sanityData.church_number.replace(
                /-/g,
                ''
              )}`}
            >
              {metaData.sanityData.church_number}
            </Styled.a>
          </p>
          <ContactHeading>Email</ContactHeading>

          <p>
            <Styled.a href={`mailto:${metaData.sanityData.church_email}`}>
              {metaData.sanityData.church_email}
            </Styled.a>
          </p>

          <ContactHeading pb={3}>Contact Form</ContactHeading>

          <Box
            as="form"
            name="contact"
            method="post"
            action="/email-sent/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="Contact form" />
            <p hidden>
              <label>
                Don’t fill this out:{' '}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>

            <Label htmlFor="name">Your name:</Label>
            <Input id="name" name="name" mb={2} p={1} onChange={handleChange} />

            <Label htmlFor="email">Your email address:</Label>
            <Input
              id="email"
              name="email"
              type="email"
              onChange={handleChange}
              mb={2}
              p={1}
            />

            <Label htmlFor="message">Your Message:</Label>
            <Textarea
              name="message"
              id="message"
              rows="6"
              mb={2}
              p={1}
              onChange={handleChange}
            />

            <Button type="submit" py={1} px={2}>
              Send
            </Button>
          </Box>
        </ContentContainer>
      </Box>
    </>
  );
};

export default Page;

export const query = graphql`
  query {
    page: sanityPage(slug: { current: { eq: "contact" } }) {
      title
      _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        hotspot {
          x
          y
        }
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
